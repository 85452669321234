import { combineReducers } from "redux";
import {
  LINK_CONSENT_FORM_SUCCESS,
  UNLINK_CONSENT_FORM_SUCCESS,
} from "../../consent";
import * as types from "./playerTypes";

// Players
const playersById = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYERS_SUCCESS: {
      return {
        ...payload,
      };
    }

    case LINK_CONSENT_FORM_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...state[payload.playerId],
          consent: "Received",
        },
      };
    }

    case UNLINK_CONSENT_FORM_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...state[payload.playerId],
          consent: "Revoked",
        },
      };
    }

    case types.CANDIDATE_REVIEW_INCOMPLETE_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...state[payload.playerId],
          status: payload.status,
        },
      };
    }

    case types.CANDIDATE_REVIEW_SUBMITTED_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...state[payload.playerId],
          status: payload.status,
        },
      };
    }

    case types.APPROVE_CANDIDATE_PROFILE_SUCCESS:
    case types.REJECT_CANDIDATE_PROFILE_SUCCESS:
    case types.REVIEW_COMPLETE_CANDIDATE_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...state[payload.playerId],
          status: payload.status,
        },
      };
    }

    default:
      return state;
  }
};

const allPlayerIds = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_PLAYERS_SUCCESS: {
      return [...Object.keys(payload)];
    }
    default:
      return state;
  }
};

const players = combineReducers({
  byId: playersById,
  allIds: allPlayerIds,
});

// Basic
const basic = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.basic,
        },
      };
    }
    case types.GET_LITE_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.basicInfo,
        },
      };
    }

    case LINK_CONSENT_FORM_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...state[payload.playerId],
          consent: "Received",
        },
      };
    }

    case UNLINK_CONSENT_FORM_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...state[payload.playerId],
          consent: "Revoked",
        },
      };
    }

    case types.CANDIDATE_REVIEW_INCOMPLETE_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...state[payload.playerId],
          status: payload.status,
        },
      };
    }

    case types.CANDIDATE_REVIEW_SUBMITTED_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...state[payload.playerId],
          status: payload.status,
        },
      };
    }

    case types.APPROVE_CANDIDATE_PROFILE_SUCCESS:
    case types.REJECT_CANDIDATE_PROFILE_SUCCESS:
    case types.REVIEW_COMPLETE_CANDIDATE_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...state[payload.playerId],
          status: payload.status,
        },
      };
    }

    case types.TOGGLE_CANDIDATE_PAID_STATUS_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...state[payload.playerId],
          isPaid: payload.isPaid,
        },
      };
    }

    default:
      return state;
  }
};

// Stats

const stats = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          negativePercentage: payload.stats["negative_percentage"],
          neutralPercentage: payload.stats["neutral_percentage"],
          positivePercentage: payload.stats["positive_percentage"],
          twitter: {
            negativePercentage: payload.stats["tweets"]["negative_percentage"],
            neutralPercentage: payload.stats["tweets"]["neutral_percentage"],
            positivePercentage: payload.stats["tweets"]["positive_percentage"],
            total: payload.stats["tweets"]["total"],
          },
          instagram: {
            negativePercentage:
              payload.stats["instagram"]["negative_percentage"],
            neutralPercentage: payload.stats["instagram"]["neutral_percentage"],
            positivePercentage:
              payload.stats["instagram"]["positive_percentage"],
            total: payload.stats["instagram"]["total"],

          },
          youtube: {
            negativePercentage: payload.stats["videos"]["negative_percentage"],
            neutralPercentage: payload.stats["videos"]["neutral_percentage"],
            positivePercentage: payload.stats["videos"]["positive_percentage"],
            total: payload.stats["videos"]["total"],

          },
          newspaper: {
            negativePercentage: payload.stats["article"]["negative_percentage"],
            neutralPercentage: payload.stats["article"]["neutral_percentage"],
            positivePercentage: payload.stats["article"]["positive_percentage"],
            total: payload.stats["article"]["total"],

          },
        },
      };
    }
    case types.GET_LITE_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          negativePercentage: payload.stats["negative_percentage"],
          neutralPercentage: payload.stats["neutral_percentage"],
          positivePercentage: payload.stats["positive_percentage"],
          twitter: {
            negativePercentage: payload.stats["tweets"]["negative_percentage"],
            neutralPercentage: payload.stats["tweets"]["neutral_percentage"],
            positivePercentage: payload.stats["tweets"]["positive_percentage"],
            total: payload.stats["tweets"]["total"],
          },
          instagram: {
            negativePercentage:
              payload.stats["instagram"]["negative_percentage"],
            neutralPercentage: payload.stats["instagram"]["neutral_percentage"],
            positivePercentage:
              payload.stats["instagram"]["positive_percentage"],
            total: payload.stats["instagram"]["total"],

          },
          youtube: {
            negativePercentage: payload.stats["videos"]["negative_percentage"],
            neutralPercentage: payload.stats["videos"]["neutral_percentage"],
            positivePercentage: payload.stats["videos"]["positive_percentage"],
            total: payload.stats["videos"]["total"],

          },
          newspaper: {
            negativePercentage: payload.stats["article"]["negative_percentage"],
            neutralPercentage: payload.stats["article"]["neutral_percentage"],
            positivePercentage: payload.stats["article"]["positive_percentage"],
            total: payload.stats["article"]["total"],

          },
        },
      };
    }
    default:
      return state;
  }
};

// Contact
const contact = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.contact,
        },
      };
    }
    case types.GET_LITE_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.playerContact,
        },
      };
    }

    default:
      return state;
  }
};

// Deep profile
const deepProfile = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.issues,
        },
      };
    }
    default:
      return state;
  }
};

//  Traits
const traits = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.traits,
        },
      };
    }
    default:
      return state;
  }
};

const customTraits = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.customTraits,
        },
      };
    }

    default:
      return state;
  }
};
const behaviourRisk = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.behaviourRisk,
        },
      };
    }
    default:
      return state;
  }
};

const filterCandidates = (state = [], action) => {
  const { payload } = action;
  if (action.type === types.GET_FILTER_CANDIDATES_SUCCESS) return payload;
  return state;
}

const updateFilter = (state = {
  athleteType: "All",
  conferenceType: "All",
  conference: "",
  sport: "",
  unit: "",
  position: "",
  team: ""
}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.UPDATE_FILTER_VALUE: {

      return {
        ...state,
        [payload.key]: payload.value,
      };
    }

    case types.RESET_FILTER_VALUE: {

      return {
        "conference": "",
        "sport": "",
        "unit": "",
        "position": "",
        "athleteType": "All",
        "conferenceType": "All",
        "team": ""
      }
    }


    default:
      return state;
  }
}

const resetFilter = (state = {}, action) => {
  switch (action.type) {
    case types.RESET_FILTER_VALUE: {

      return {
        "conference": "",
        "sport": "",
        "unit": "",
        "position": "",
        "athleteType": "All",
        "conferenceType": "All",
        "team": ""
      }
    }

    default:
      return state;
  }
}

const resetSelectedFilter = (state = {
  sport: "",
  unit: "",
  position: "",
}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.RESET_SELECTED_FILTER_VALUE: {

      return {
        "sport": "",
        "unit": "",
        "position": "",
      }
    }
    default:
      return state;
  }
}
const isFiltered = (state = false, action) => {
  const { payload } = action
  switch (action.type) {
    case types.IS_FILTERED_DATA: {

      return payload
    }

    default:
      return state;
  }
}

const filterPlayers = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_FILTER_PLAYERS_SUCCESS: {
      return payload
    }

    default:
      return state
  }
}

const updateTeamFilter = (state = {
  athlete_type: "",
  sport: "",
  unit: "",
  position: "",
}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.UPDATE_TEAM_FILTER_VALUE: {

      return {
        ...state,
        [payload.key]: payload.value,
      };
    }

    case types.RESET_TEAM_FILTER_VALUE: {
      return {
        "sport": "",
        "unit": "",
        "position": "",
        "athlete_type": "",
      }
    }


    default:
      return state;
  }
}

const isTeamFiltered = (state = false, action) => {
  const { payload } = action
  switch (action.type) {
    case types.IS_TEAM_FILTERED_DATA: {

      return payload
    }

    default:
      return state;
  }
}

const actionPlan = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        ...payload.actionPlan,
      };
    }

    case types.ADD_ACTION_PLAN_COMMENT_SUCCESS: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        // [`${playerId}.${categoryId}`]: payload.content,
        [`${playerId}.${categoryId}`]: categoryId === "Custom" ? [...payload.prevData, payload.data] : payload.content,
      };
    }
    case types.DELETE_ACTION_PLAN_COMMENT_SUCCESS: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        [`${playerId}.${categoryId}`]: payload.prevData.filter(v => v._id !== payload._id),
      };
    }

    default:
      return state;
  }
};

// Executive summery
const executiveSummery = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        ...payload.executiveSummery,
      };
    }

    case types.ADD_EXECUTIVE_SUMMERY_COMMENT_SUCCESS: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        [`${playerId}.${categoryId}`]: categoryId === "Custom" ? [...payload.prevData, payload.data] : payload.content,
      };
    }

    case types.DELETE_PSYCH_SUMMERY_COMMENT_SUCCESS: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        [`${playerId}.${categoryId}`]: payload.prevData.filter((v) => v._id !== payload._id),
      };
    }

    default:
      return state;
  }
};

// Risk
const riskCategories = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.risk,
        },
      };
    }

    case types.UPDATE_RISK_SCORE_SUCCESS: {
      // let riskData = Object.keys(payload.prevData).reduce((obj, category) => {
      //   if(category === payload.categoryId) {
      //     obj[category] = {
      //       ...payload.risk,
      //       id: payload.categoryId,
      //     };
      //   } else {
      //     obj[category] = {
      //       id: category,
      //       score: payload.prevData[category]["score"],
      //       text: payload.prevData[category]["text"],
      //       comments: payload.prevData[category]["comments"],
      //     };
      //   }

      //   return obj;
      // }, {});

      return {
        ...state,
        // [payload.playerId]: {
        //   ...riskData,
        // },
        [payload.playerId]: {
          ...state[payload.playerId],
          [payload.categoryId]: {
            ...payload.risk,
            id: payload.categoryId,
            comments: state[payload.playerId][payload.categoryId]["comments"],
          },
        },
      };
    }

    default:
      return state;
  }
};

const riskComments = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        ...payload.riskComments,
      };
    }

    case types.ADD_RISK_COMMENT_SUCCESS: {
      return {
        [payload.commentId]: {
          id: payload.commentId,
          content: payload.comment,
        },
        ...state,
      };
    }

    case types.DELETE_RISK_COMMENT_SUCCESS: {
      delete state[payload.commentId];
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

const playerCategoryComments = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      let comments = Object.keys(payload.risk).reduce((obj, riskId) => {
        obj[`${payload.id}.${riskId}`] = payload.risk[riskId]["comments"];
        return obj;
      }, {});

      return {
        ...state,
        ...comments,
      };
    }

    case types.ADD_RISK_COMMENT_SUCCESS: {
      let updatedComments = [
        payload.commentId,
        ...state[`${payload.playerId}.${payload.categoryId}`],
      ];

      return {
        ...state,
        [`${payload.playerId}.${payload.categoryId}`]: updatedComments,
      };
    }

    case types.DELETE_RISK_COMMENT_SUCCESS: {
      let updatedComments = state[
        `${payload.playerId}.${payload.categoryId}`
      ].filter((commentId) => commentId !== payload.commentId);

      return {
        ...state,
        [`${payload.playerId}.${payload.categoryId}`]: updatedComments,
      };
    }

    default:
      return state;
  }
};

const risk = combineReducers({
  categories: riskCategories,
  categoryComments: playerCategoryComments,
  comments: riskComments,
});

// Feed

const twitterFeedById = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.GET_PLAYER_FEED_SUCCESS &&
    payload.source === "twitter"
  ) {
    return {
      ...state,
      [payload.id]: {
        negatives: payload.negatives,
        positives: payload.positives,
        neutrals: payload.neutrals,
        graphs: payload.graphs,
      },
    };
  }

  return state;
};

const instagramFeedById = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.GET_PLAYER_FEED_SUCCESS &&
    payload.source === "instagram"
  ) {
    return {
      ...state,
      [payload.id]: {
        negatives: payload.negatives,
        positives: payload.positives,
        neutrals: payload.neutrals,
        graphs: payload.graphs,
      },
    };
  }

  return state;
};

const youtubeFeedById = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.GET_PLAYER_FEED_SUCCESS &&
    payload.source === "youtube"
  ) {
    return {
      ...state,
      [payload.id]: {
        negatives: payload.negatives,
        positives: payload.positives,
        neutrals: payload.neutrals,
        graphs: payload.graphs,
      },
    };
  }

  return state;
};

const newspaperFeedById = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.GET_PLAYER_FEED_SUCCESS &&
    payload.source === "newspaper"
  ) {
    return {
      ...state,
      [payload.id]: {
        negatives: payload.negatives,
        positives: payload.positives,
        neutrals: payload.neutrals,
        graphs: payload.graphs,
      },
    };
  }

  return state;
};

const feedDataById = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYER_FEED_SUCCESS: {
      return {
        ...state,
        ...payload.data,
      };
    }
    default:
      return state;
  }
};

const feed = combineReducers({
  twitter: twitterFeedById,
  instagram: instagramFeedById,
  youtube: youtubeFeedById,
  newspaper: newspaperFeedById,
  dataById: feedDataById,
});

// Possible duplicate info
const possibleDuplicateInfo = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.possibleDuplicateInfo,
        },
      };
    }
    case types.GET_LITE_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.possibleDuplicateInfo,
        },
      };
    }

    // not needed? ask eshan

    // case types.APPROVE_CANDIDATE_PROFILE_SUCCESS: {
    //   return {
    //     ...state,
    //     [payload.playerId]: {
    //       ...state[payload.playerId],
    //       isPossibleDuplicate: false,
    //     },
    //   };
    // }

    default:
      return state;
  }
};

// Notes
const notes = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.notes,
        },
      };
    }

    case types.UPDATE_NOTES_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: {
          ...payload.notes,
        },
      };
    }

    default:
      return state;
  }
};

const links = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: payload.links,
      };
    }

    case types.ADD_CANDIDATE_LINK_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: payload.links,
      };
    }

    case types.DELETE_CANDIDATE_LINK_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: state[payload.playerId].filter(
          (link) => link.link_id !== payload.linkId
        ),
      };
    }

    default:
      return state;
  }
};

// Message
const message = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        [payload.id]: {
          title: payload.message["title"],
          content: payload.message["content"],
          show: payload.message["show"],
          severity: payload.message["severity"],
          buttonLabel: payload.message["button_label"],
        },
      };
    }
    default:
      return state;
  }
};

const reports = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        [payload.id]: payload.reports,
      };
    }

    default:
      return state;
  }
};

const reportSettings = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PLAYER_SUCCESS: {
      return {
        [payload.id]: payload.reportSettings,
      };
    }

    case types.TOGGLE_FULL_REPORT_PAYMENT_STATUS_SUCCESS:
    case types.TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS:
    case types.TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS: {
      const currentSettings = state[payload.playerId];
      return {
        [payload.playerId]: {
          ...currentSettings,
          [payload.reportType]: payload.allowed,
        },
      };
    }

    default:
      return state;
  }
};

const requestedReportData = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_ALLREADY_REQUESTED_PLUS_REPORTS: {
      return payload
    }

    default:
      return state
  }
}


const sortingOrderBy = (state = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case types.SELECTED_SORTING_TYPE: {
      return payload
    }

    default:
      return state
  }
}

const playerOrder = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.SELECTED_SORT_ORDER: {
      const newData = payload && payload.map(v => v.value)
      return {
        "sortingOrder": newData,
        "sortTypeAndOrder": payload
      }
    }

    default:
      return state
  }
}

const selectedTeamByName = (state = {}, action) => {
  const { payload } = action;
  if (action.type === types.GET_TEAM_BY_NAME_SUCCESS) return payload;
  return state
};

const selectedCommunicationData = (state = {}, action) => {
  const { payload } = action;
  if (action.type === types.GET_COMMUNICATION_DATA_SUCCESS || action.type === types.RESET_COMMUNICATION_DATA) return payload;
  return state;
};

export default combineReducers({
  players,
  basic,
  possibleDuplicateInfo,
  contact,
  deepProfile,
  traits,
  customTraits,
  risk,
  actionPlan,
  executiveSummery,
  reportSettings,
  stats,
  feed,
  notes,
  links,
  message,
  reports,
  filterCandidates,
  updateFilter,
  resetFilter,
  isFiltered,
  updateTeamFilter,
  isTeamFiltered,
  filterPlayers,
  resetSelectedFilter,
  requestedReportData,
  sortingOrderBy,
  playerOrder,
  behaviourRisk,
  selectedTeamByName,
  selectedCommunicationData
});
