import { combineReducers } from "redux";
import * as types from "./sportsType";

const getAllSportsListData = (state = [], action) => {
  const { payload } = action;
  if (action.type === types.GET_ALL_SPORTS_SUCCESS) return payload
  return state;
};

const getSportData = (state = {}, action) => {
  const { payload } = action;
  if (action.type === types.GET_SPORT_BY_ID_SUCCESS ||
      action.type === types.DESELECT_SPORT
  ) return payload;
  return state;
};

const allSportsUnitsData = (state = [], action) => {
    const { payload } = action;
    if (action.type === types.GET_ALL_SPORTS_UNITS_SUCCESS) return payload;
    return state;
};

const unitSport = (state = {}, action) => {
  const { payload } = action;
  if (action.type === types.GET_UNIT_BY_ID_SUCCESS ||
      action.type === types.DESELECT_SPORT_UNIT
  ) return payload;
  return state;
};

const unitsBySportId = (state = [], action) => {
    const { payload } = action;
    if (action.type === types.GET_SPORT_UNIT_BY_SPORT_ID_SUCCESS) return payload;
    return state
}

const sportPositions = (state = [], action) => {
  const { payload } = action;
  if (action.type === types.GET_SPORT_POSITION_BY_UNIT_ID_SUCCESS) return payload;
  return state;
};

const sportsPositionById = (state = {}, action) => {
    const { payload } = action;
    switch (action.type) {
        case types.GET_SPORT_POSITION_BY_ID_SUCCESS: {
            return payload;
        }

        case types.DESELECT_SPORT_POSITION: {
            return payload;
        }

        default:
            return state;
    }
};

const getUserTypesList = (state = [], action) => {
  const { payload } = action;
  if (action.type === types.GET_TEAM_USERS_SUCCESS) return payload;
  return state;
};

export default combineReducers({
    sportsListData: getAllSportsListData,
    getSportData,
    sportUnits: allSportsUnitsData,
    unitSport,
    unitsBySportId,
    sportPositions,
    sportsPositionById,
    userTypesList:getUserTypesList,
  });