import { combineReducers } from "redux";
import * as types from "./sportsType";

const sportsListLoading = (state = false, action) => {
    switch (action.type) {
        case types.GET_ALL_SPORTS_REQUEST: {
            return true;
        }

        case types.GET_ALL_SPORTS_SUCCESS:
        case types.GET_ALL_SPORTS_FAILED: {
            return false;
        }

        default:
            return state;
    }
};

const selectSportByIdLoading = (state = false, action) => {
    switch (action.type) {
        case types.GET_SPORT_BY_ID_REQUEST: {
            return true;
        }

        case types.GET_SPORT_BY_ID_SUCCESS:
        case types.GET_SPORT_BY_ID_FAILED:
        case types.DESELECT_SPORT:{
            return false;
        }

        default:
            return state;
    }
};

const searchKey = (state = "", action) => {
    switch (action.type) {
        case types.SEARCH_SPORT: {
            return action.payload.key;
        }

        case types.RESET_SEARCH: {
            return "";
        }

        default:
            return state;
    }
};

const selectedSportId = (state = null, action) => {
    switch (action.type) {
        case types.SELECT_SPORT: {
            return action.payload.sportId;
        }

        case types.DESELECT_SPORT: {
            return null;
        }

        default:
            return state;
    }
};

const loadingAllSportsUnits = (state = false, action) => {
    switch (action.type) {
        case types.GET_ALL_SPORTS_UNITS_REQUEST:
        case types.GET_UNIT_BY_ID_REQUEST:{
            return true;
        }

        case types.GET_ALL_SPORTS_UNITS_SUCCESS:
        case types.GET_ALL_SPORTS_UNITS_FAILED:
        case types.GET_UNIT_BY_ID_SUCCESS:
        case types.GET_UNIT_BY_ID_FAILED:
        case types.DESELECT_SPORT_UNIT:{
            return false;
        }

        default:
            return state;
    }
};

const loadSportUnitsBySportId = (state = false, action) => {
    switch (action.type) {
        case types.GET_UNIT_BY_ID_REQUEST:{
            return true;
        }

        case types.GET_UNIT_BY_ID_SUCCESS:
        case types.GET_UNIT_BY_ID_FAILED:{
            return false;
        }

        default:
            return state;
    }
};

const selectedSportUnitId = (state = {}, action) => {
    switch (action.type) {
        case types.SELECT_SPORT_UNIT: {
            return action.payload.unitId;
        }

        case types.DESELECT_SPORT_UNIT: {
            return {};
        }

        default:
            return state;
    }
};

const loadingSportsPositions = (state = [], action) => {
    switch (action.type) {
        case types.GET_SPORT_POSITION_BY_UNIT_ID_REQUEST: {
            return true;
        }

        case types.GET_SPORT_POSITION_BY_UNIT_ID_SUCCESS:
        case types.GET_SPORT_POSITION_BY_UNIT_ID_FAILED: {
            return false
        }

        default:
            return state;
    }
};

const loadingSportsPositionById = (state = {}, action) => {
    switch (action.type) {
        case types.GET_SPORT_POSITION_BY_ID_REQUEST: {
            return true;
        }

        case types.GET_SPORT_POSITION_BY_ID_SUCCESS:
        case types.GET_SPORT_POSITION_BY_ID_FAILED:
        case types.DESELECT_SPORT_POSITION:{
            return false
        }

        default:
            return state;
    }
};

const selectedSportsPageId = (state = 'list', action) => {
    switch (action.type) {
      
      case types.SELECTED_PAGE_TYPE: {
        return action.payload;
      }
  
      default:
        return state;
    }
  };
  
  const selectedSportsTypeId = (state = '', action) => {
    switch (action.type) {
      
      case types.SELECTED_SPORTS_TYPE: {
        return action.payload;
      }

        case types.DESELECT_SPORT: {
            return state
        }
  
      default:
        return state;
    }
  };

  const resetSportCreationError = (state = [], action) => {
      if (action.type === types.RESET_SPORT_CREATION_ERROR) {
          return {
              ...state,
              error: null
          };
      }

      return state;
  };

const resetUnitCreationError = (state = [], action) => {
    if (action.type === types.RESET_UNIT_CREATION_ERROR) {
        return {
            ...state,
            error: null
        };
    }

    return state;
};

const resetSportPositionCreationError = (state = [], action) => {
    if (action.type === types.RESET_SPORT_POSITION_CREATION_ERROR) {
        return {
            ...state,
            error: null,
        }
    }

    return state;
};

const selectedFormName = (state = null, action) => {
    switch (action.type) {
        case types.FORM_NAME_SELECTED: {
            return action.payload.name;
        }

        case types.FORM_NAME_DESELECTED: {
            return null;
        }

        default:
            return state;
    }
};

const selectedFormState = (state = null, action) => {
    switch (action.type) {
        case types.FORM_STATE_SELECTED: {
            return action.payload.name;
        }

        case types.FORM_STATE_DESELECTED: {
            return null;
        }

        default:
            return state;
    }
};

export default combineReducers({
    sportsListLoading,
    selectSportByIdLoading,
    searchKey,
    selectedSportId,
    loadingAllSportsUnits,
    loadSportUnitsBySportId,
    selectedSportUnitId,
    loadingSportsPositions,
    loadingSportsPositionById,
    selectedSportsPageId,
    selectedSportsTypeId,
    resetSportCreationError,
    resetUnitCreationError,
    resetSportPositionCreationError,
    selectedFormName,
    selectedFormState,
});